<template>
  <div class="home_screen" v-if="profileType == 'Partner'">
    <v-container fluid class="mt-0 pt-0">
      <v-row class="mt-0 pt-0">
        <v-col cols="12" sm="6" md="9" class="mt-0 pt-0">
          <v-col
            cols="12"
            style="justify-content:space-between; flex-direction: row; display: flex; align-items: center;"
          >
            <label class="item_title">Distributed Analysis</label>
            <div>
              <v-btn
                text
                small
                @click="goToDistributionHistory"
                color="#747474"
              >
                View all history >>
              </v-btn>
              <v-btn text small @click="goToDistributions" color="#747474">
                Distribution<v-icon class="ml-1">mdi-barcode-scan</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-card class="pt-10 px-3 pb-0 rounded-lg">
            <partner-home-charts />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3" class="mt-0 pt-0 d-flex flex-column">
          <div
            style="justify-content:space-between; flex-direction: row; display: flex; align-items: center;"
            class="pt-3 pb-3"
          >
            <label class="item_title">Events</label>
          </div>
          <v-hover v-slot="{ hover }">
            <v-card
              class="pt-5 px-3 pb-0 rounded-lg px-5 d-flex flex-column"
              style="flex-grow: 1;"
              @click="goToEventHome"
              :class="{ 'on-hover': hover, 'rounded-lg': true }"
            >
              <div style="flex-grow: 1">
                <div
                  v-for="event in events"
                  :key="event._id"
                  class="mb-4 d-flex"
                >
                  <div><v-img :src="event.cover" height="70" width="70" /></div>
                  <div
                    style="flex-grow: 1; padding-left: 10px; padding-right: 10px;"
                  >
                    <div
                      style="overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;"
                      class="url-button"
                      @click.stop="goToEventDetails(event._id)"
                      @mousedown.stop
                    >
                      {{ event.title }}
                    </div>
                    <div class="limited-text">
                      {{ event.text }}
                    </div>
                  </div>
                  <div style="font-size: 12px;">
                    {{ getDate(event.createdAt) }}
                  </div>
                </div>
              </div>
              <v-divider class="mx-7" />
              <v-card-actions style="justify-content:flex-end">
                <v-btn text x-small color="#7024C4" @click="goToEventHome">
                  View All
                  <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" style="min-width: 240px;">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToOrganization"
                class="d-flex flex-column"
                style="height: 100%;"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">My Organization</label>
                </v-card-title>
                <v-divider class="mx-7" />
                <v-card-text style="flex-grow: 1">
                  <div
                    class="d-flex flex-row"
                    style="justify-content: space-between; flex-grow: 1;"
                    v-if="profile.organization"
                  >
                    <div>
                      <div
                        style="font-family: 'Poppins-SemiBold'; color: #7024c4;"
                      >
                        {{ profile.organization.name }}
                      </div>
                      <div
                        style="font-family: 'Poppins-Medium'; color: #848299;"
                      >
                        {{ profile.organization.url }}
                      </div>
                      <div class="text">
                        {{ profile.organization.description }}
                      </div>
                      <div style="color: #848299; margin-top: 5px">
                        Service: {{ profile.organization.service }}
                      </div>
                    </div>
                    <editable-avatar
                      :image="profile.organization.image"
                      :editable="false"
                      :size="60"
                    />
                  </div>
                </v-card-text>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn
                    text
                    x-small
                    color="#7024C4"
                    @click.stop="goToOrganization"
                  >
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="min-width: 240px;">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToTeamMembers"
                style="height: 100%"
                class="d-flex flex-column"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Team members</label>
                  <label class="count_label" v-if="homeInfo.employee">
                    {{ homeInfo.employee }}
                    {{ homeInfo.employee == 1 ? "employee" : "employees" }}
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between; flex-grow: 1;"
                >
                  <v-card class="mx-7 my-5" width="120">
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      New
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 48px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.newEmployees ? homeInfo.newEmployees : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      Employees
                    </v-card-text>
                  </v-card>
                  <div class="mr-8 mt-8">
                    <v-img src="@/assets/referrals.svg" contain />
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToTeamMembers">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="min-width: 240px;">
          <v-sheet outlined color="white" rounded style="height: 100%">
            <v-hover v-slot="{ hover }">
              <v-card
                height="100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToClients"
                style="height: 100%"
                class="d-flex flex-column"
              >
                <v-card-title
                  class="d-flex flex-row pb-1 px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Clients</label>
                  <label class="count_label ml-4" v-if="homeInfo.client">
                    {{ homeInfo.client }}
                    {{ homeInfo.client == 1 ? "account" : "accounts" }}
                  </label>
                </v-card-title>
                <v-divider class="mx-7" />
                <div
                  class="d-flex flex-row"
                  style="justify-content: space-between; flex-grow: 1"
                >
                  <v-card class="mx-7 my-5" width="120">
                    <v-card-title
                      class="pa-0 ma-0 d-flex justify-center"
                      style="background: #feb934; color: #6e3dc6;"
                    >
                      New
                    </v-card-title>
                    <v-card-text
                      class="mt-5 d-flex justify-center"
                      style="color: #482684; font-size: 48px; font-weight: 700;"
                    >
                      <label>{{
                        homeInfo.newClients ? homeInfo.newClients : 0
                      }}</label>
                    </v-card-text>
                    <v-card-text class="pa-0 d-flex justify-center">
                      Clients
                    </v-card-text>
                  </v-card>
                  <div style="width: 60px" class="mr-12">
                    <v-row class="users_image_section">
                      <v-col
                        cols="4"
                        v-for="(user, index) in user_images"
                        v-bind:key="index"
                      >
                        <editable-avatar
                          :image="user.image"
                          :editable="false"
                          :size="24"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-divider class="mx-7" />
                <v-card-actions style="justify-content:flex-end">
                  <v-btn text x-small color="#7024C4" @click="goToClients">
                    View detail
                    <v-icon color="#7024C4">mdi-chevron-double-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="min-width: 240px">
          <v-sheet outlined color="white" rounded>
            <v-hover v-slot="{ hover }">
              <v-card
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToGoods"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Items</label>
                  <label class="count_label" v-if="homeInfo.goods"
                    >{{ homeInfo.goods }}
                    {{ homeInfo.goods == 1 ? "item" : "items" }}</label
                  >
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-sheet outlined color="white" rounded>
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToReferrals"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Referrals</label>
                  <label class="count_label" v-if="homeInfo.referral"
                    >{{ homeInfo.referral }}
                    {{
                      homeInfo.referral == 1 ? "referral" : "referrals"
                    }}</label
                  >
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="6" md="4" style="min-width: 240px">
          <v-sheet outlined color="white" rounded>
            <v-hover v-slot="{ hover }">
              <v-card
                style="height: 100%"
                :class="{ 'on-hover': hover, 'rounded-lg': true }"
                @click="goToChat"
              >
                <v-card-title
                  class="d-flex flex-row px-7"
                  style="justify-content: space-between"
                >
                  <label class="item_title">Contact Us</label>
                  <label class="count_label" v-if="homeInfo.conversation"
                    >{{ homeInfo.conversation }}
                    {{
                      homeInfo.conversation == 1
                        ? "conversation"
                        : "conversations"
                    }}</label
                  >
                </v-card-title>
              </v-card>
            </v-hover>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <edit-organization-dialog
      :item="profile.organization"
      :dialog="organizationDialog"
      v-if="organizationDialog && profile.organization"
      :onCloseDialog="onCloseOrganizationDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";
import EditOrganizationDialog from "../../components/partner/EditOrganizationDialog.vue";
import dateFormat from "dateformat";
import PartnerHomeCharts from "../../components/partner/PartnerHomeCharts.vue";

export default {
  name: "Home",
  components: {
    EditableAvatar,
    EditOrganizationDialog,
    PartnerHomeCharts,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "ClientId", value: "client_id" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      selected: null,
      organizationDialog: false,
      rejectDialog: false,
      center: null,
      homeInfo: {},
      user_images: [],
      organization: null,
      events: [],
    };
  },
  methods: {
    ...mapActions({
      fetchAllReferrals: "referral/fetchAllReferrals",
      editReferral: "referral/editReferral",
      createNewConversation: "chat/createNewConversation",
      fetchProfile: "auth/fetchProfile",
      fetchHomeInfo: "auth/fetchHomeInfo",
      fetchAllEvents: "event/fetchAllEvents",
    }),
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    clientsClass(item) {
      if (item._id == this.profile._id) {
        return "selected";
      }
      return "";
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "Pending";
        case 1:
          return "Approved";
        case 2:
          return "Completed";
        case 3:
          return "Rejected";
      }
    },
    onDistribute(item) {
      this.selected = item;
      this.distributionDialog = true;
    },
    onCloseDialog() {
      this.distributionDialog = false;
      this.rejectDialog = false;
    },
    onDistributed() {
      this.distributionDialog = false;
      this.editReferral({ _id: this.selected._id, status: 2 }).catch(
        (error) => {
          console.log(error.response.data.message);
        }
      );
    },
    goToClients() {
      this.$router.push({ name: "partnerClients" });
    },
    goToReferrals() {
      this.$router.push({ name: "partner-referrals" });
    },
    goToReject(item) {
      this.selected = item;
      this.rejectDialog = true;
    },
    onRejected() {
      this.rejectDialog = false;
      this.editReferral({ _id: this.selected._id, status: 3 }).catch(
        (error) => {
          console.log(error.response.data.message);
        }
      );
    },
    goToEventDetails(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
    goToTeamMembers() {
      this.$router.push({ name: "partner-teammembers" });
    },
    goToGoods() {
      this.$router.push({ name: "partner-goods" });
    },
    goToChat() {
      this.createNewConversation()
        .then((res) => {
          this.$router.push({
            name: "chat-details",
            params: { id: res._id },
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    goToHistory() {
      this.$router.push({
        name: "partner-distributions",
      });
    },
    editProfile() {
      this.$router.push({
        name: "partner-profile",
      });
    },
    goToOrganization() {
      this.organizationDialog = true;
    },
    onCloseOrganizationDialog() {
      this.organizationDialog = false;
      this.fetchProfile()
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error.respnose.data.message);
          }
        });
    },
    loadEvents() {
      this.loading = true;
      this.fetchAllEvents()
        .then((res) => {
          this.loading = false;
          this.events = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    getDate(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "mm/dd/yy");
    },
    goToEventHome() {
      this.$router.push({ name: "event-home" });
    },
    goToDistributionHistory() {
      this.$router.push({ name: "partner-distribution-history" });
    },
    goToDistributions() {
      this.$router.push({ name: "partner-distribution" });
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      referrals: "referral/getAllReferrals",
    }),
  },
  watch: {
    "profile.location": function(newValue) {
      if (newValue.length == 2) {
        this.center = {
          lat: parseFloat(newValue[0]),
          lng: parseFloat(newValue[1]),
        };
      }
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllReferrals()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
    if (
      this.profile &&
      this.profile.location &&
      this.profile.location.length == 2
    ) {
      this.center = {
        lat: parseFloat(this.profile.location[0]),
        lng: parseFloat(this.profile.location[1]),
      };
    }
    this.loading = true;
    this.fetchHomeInfo()
      .then((data) => {
        this.homeInfo = { ...data };
        console.log(this.homeInfo);
        this.user_images = this.homeInfo.client_images;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error.response.data.message);
        }
      });
    this.loadEvents();
  },
};
</script>
<style scoped>
.home_screen {
  background-color: #f4f9ff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #423f63;
  font-size: 10px;
}
.item_title {
  color: #423f63;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.count_label {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  /* padding-left: 40px; */
  justify-content: center;
  max-width: 200px;
}
.on-hover {
  background-color: #f3e9ff !important;
}
</style>

<template>
  <highcharts :options="chartOptions" style="height:280px" ref="chart" />
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      chartOptions: {
        showInLegend: true,
        chart: {
          renderTo: "container",
          // type: "area",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: " ", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        plotOptions: {
          column: {
            depth: 25,
          },
          // series: {
          //   color: "#7024C4",
          // },
        },
        xAxis: {
          labels: {
            formatter: function() {
              let myDate = new Date(this.value);
              return moment(String(myDate)).format("MMM DD");
            },
          },
        },
        yAxis: {
          title: "",
        },
        series: [],
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
        },
        tooltip: {
          useTHML: true,
          formatter: function() {
            let myDate = new Date(this.x);
            return (
              moment(String(myDate)).format("MMM DD") +
              "<br>" +
              this.series.name +
              `: <span style="font-weight:bold">` +
              this.y +
              "</span>"
            );
          },
        },
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getDistributionList: "auth/getDistributionList",
      getValues: "variable/getPartnerValues",
    }),
    importData(res, categories) {
      var data = [];
      categories.map(() => {
        data.push([]);
      });
      for (var i = 0; i < res.length; i++) {
        for (var j = 0; j < categories.length; j++) {
          var stamp = new Date(res[i]._id).getTime();
          data[j].push([stamp, res[i][`${categories[j].text}`]]);
        }
      }
      this.$refs.chart.chart.hideLoading();
      // Enable when needed to show real data
      this.chartOptions.series = data.map((d, index) => ({
        showInLegend: true,
        name: categories[index].text,
        data: d,
      }));
    },
  },
  mounted() {
    this.loading = true;
    this.$refs.chart.chart.showLoading();
    this.getDistributionList()
      .then((res) => {
        this.getValues({ title: "Categories" }).then((categories) => {
          this.loading = false;
          this.importData(res, categories);
        });
      })
      .catch((error) => {
        this.loading = false;
        console.log(error.response.data.message);
        this.$refs.chart.chart.hideLoading();
      });
  },
};
</script>
